import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";

export const About = () => {
  return (
    <header id="about" className="p-4 my-1 rounded-3 aboutImg">
      <div className="container-fluid text-center">
        <h1 className="display-5 fw-bold">About</h1>
        <hr />
        <div className="col-md-8 mx-auto">
          <div className="fw-bold">PJ's Pub Hours:</div>
          <div className="m-2">Monday - Sunday: 1:00 pm - 2:00 am</div>
          <div>
            <strong>Happy Hour:</strong>
            <div className="m-1">Industry discount on Mondays: 15% off</div>
            <div>3:00 pm - 7:00 pm Monday – Saturday</div>
            <div>2:00 pm – 3:00 pm Sunday</div>
          </div>
          <div>
            <div className="mt-3 mb-1 fw-bold">1717 N Monroe Spokane, WA</div>
          </div>
          <div>
            <div>Phone: 509-919-3788</div>
            <div>Fax: 509-919-3792</div>
            <a href="mailto:pjspub1717@gmail.com">pjspub1717@gmail.com</a>
          </div>
        </div>
      </div>
    </header>
  );
};
